<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>
        <h1
          style="
            font-weight: 600;
            border-left: 2px solid red;
            padding-left: 10px;
          "
        >
          HR
        </h1>
      </b-breadcrumb-item>
    </b-breadcrumb>
    <h4 style="font-weight: 600; padding-left: 10px">Workforce Demographics</h4>
    <h6 style="padding-left: 10px">Data as of {{ dataDate }}</h6>

    <b-list-group style="padding-top: 10px">
      <b-row align-h="start" style="padding-bottom: 20px; padding-left: 10px">
        <b-col sm="5">
          <b-form-group
            class="formGroup"
            label="Business Unit :"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="sm"
          >
            <multiselect
              v-model="SelectBU"
              label="name"
              @input="ChangeBU"
              :options="optionsBU"
              placeholder="Select Business Unit"
              track-by="name"
              openDirection="bottom"
              :multiple="true"
              :taggable="true"
            >
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-list-group-item>
        <b-row align-self="center">
          <b-col
            align-self="center"
            style="
              margin: auto;
              padding-bottom: 40px;
              text-align: -webkit-center;
            "
          >
            <div class="card card-inverse card-success">
              <div class="card-block bg-success">
                <div class="rotate">
                  <i class="fa fa-user fa-5x"></i>
                </div>
                <h6 class="text-uppercase">Total Employees</h6>
                <h1 class="display-1" v-html="formatComma(totalEmployee)"></h1>
              </div>
            </div>
            <!-- <b-card class="align-middle" style="background:#78F0D6; max-width: 15rem; margin: auto; ">
            <div style="text-align:center;">
                <div>
                    <div>
                        <h4 class="" style='font-weight:500;'>Total Employees</h4>
                    </div>
                    <div>
                        <h5 class="" style='font-weight:300;' v-html="formatComma(totalEmployee)"></h5>
                    </div>

                </div>
            </div>
            </b-card> -->
          </b-col>
          <b-col align-self="center" style="margin: auto">
            <span>Employees by Gender</span>
            <apexchart
              width="400"
              type="donut"
              ref="donutChart"
              :options="chartOptions2"
              :series="series2"
            ></apexchart>
          </b-col>
          <b-col>
            <span>Employees by Education</span>
            <apexchart
              width="400"
              height="350"
              type="bar"
              ref="barChart"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
// New Backend (Core) API
import { fnPOST } from "@/api/api-methods";
import { APIPATH_REPORTING } from "@/api/api-paths-backend";
// import vue multiselect
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data: function () {
    return {
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      optionsBU: [
        {
          name: "BBI",
        },
        {
          name: "AOI",
        },
      ],
      SelectBU: null,
      BUList: null,
      totalEmployee: 0,
      dataDate: null,
      // REPORT DATA SAMPLE -- START
      cardData: [
        {
          label: "Total Employee",
          value: 0,
        },
      ],
      piechartData: [
        {
          label: "Gender",
          arrOptions: [],
          arrValue: [],
        },
      ],
      // REPORT DATA SAMPLE -- END
      // Chart Donut
      chartOptions2: {
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          id: "vuechart-example",
          events: {
            dataPointSelection: (e, chart, opts) => {
              this.Ftest2(opts.dataPointIndex);
            },
          },
        },
        colors: ["#78F0D6", "#006A4C"],
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
            fontWeight: "100",
          },
          dropShadow: {
            enabled: true,
          },
          // formatter: (val, opts)  => {
          //   let form = this.format(opts.seriesIndex)
          //   return form
          // },
        },

        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "45%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 5,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "Total",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: [],
      },

      series2: [],

      // End Chart Donut

      // Chart Bar
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          events: {
            dataPointSelection: (e, chart, opts) => {
              this.Ftest(opts.dataPointIndex);
            },
          },
          type: "bar",
          height: 400,
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: true,
          },
        },
        colors: ["#78F0D6", "#006A4C"],
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: "top", // top, center, bottom
            },
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#333"],
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          tickAmount: 5,
          max: 0,
          // title: {
          //   text: '$ (thousands)'
          // }
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      series_label: [],
      series_max: 0,
      series: [],
      // End Chart Bar
    };
  },
  computed: {
    computeMaxValue(arrValue) {
      let result = 0;
      if (arrValue && arrValue.length > 0) {
        result = Math.max(...arrValue);
      }
      return result;
    },
  },
  methods: {
    formatComma(value) {
      let data = value;
      if (value == null || value == undefined) {
        return data;
      } else {
        data = value.toLocaleString("en-US");
        return data;
      }
    },
    FormatDate(value) {
      if (value == null || value == undefined || value == "") {
        return " ";
      } else {
        let date = new Date(value);
        let tgl = date.toISOString().slice(8, 10);
        let bulan = this.monthNames[date.getMonth()];
        let tahun = date.toISOString().slice(0, 4);
        date = tgl + " " + bulan + " " + tahun;
        return date;
      }
    },
    Ftest(value) {
      console.log(this.series[0].data[value]);
      console.log(value);
    },
    Ftest2(value) {
      console.log(this.series[0].data[value]);
      console.log(value);
    },
    format(value) {
      // console.log(this.chartOptions2.labels[value])
      let cat = this.chartOptions2.labels[value];
      let val = this.series2[value];
      return cat + ":" + val;
    },
    ChangeBU() {
      this.BUList = [];
      if (this.SelectBU.length > 0) {
        for (let a = 0; a < this.SelectBU.length; a++) {
          this.BUList.push(this.SelectBU[a].name);
        }
      }
      //   this.series = [{
      //     name: 'Education',
      //     data: [26, 407, 751, 3209, 1098, 1234, 9, 1]
      //   }]
      //   this.series2 = [2984, 3751]
      //   this.totalEmployee = 6735
      // } else {
      //   if (this.SelectBU[0].code == 'BBI') {
      //     this.series = [{
      //       name: 'Education',
      //       data: [11, 207, 251, 1209, 698, 234, 1, 0]
      //     }]
      //     this.series2 = [1984, 1751]
      //     this.totalEmployee = 2424
      //   } else {
      //     this.series = [{
      //       name: 'Education',
      //       data: [26, 407, 751, 3209, 1098, 1234, 9, 1]
      //     }]
      //     this.series2 = [2984, 3751]
      //     this.totalEmployee = 6735
      //   }
      // }
      this.fetchData();
    },
    fetchData() {
      console.log(this.SelectBU);
      let selectedBUs = [];
      if (this.BUList) {
        selectedBUs = this.BUList;
      }
      fnPOST(APIPATH_REPORTING.empDemographSummary, {
        rpt_type: "summary",
        business_unit: selectedBUs,
      }).then((rsp) => {
        let data = rsp.data.payload;
        this.series = [
          {
            name: "Education",
            data: data.edu_total,
          },
        ];
        this.series_label = data.edu_label;
        let maxValue = Math.ceil(Math.max(...data.edu_total) / 10) * 10;
        this.series2 = data.gender_total;
        this.totalEmployee = data.emp_total;
        this.$refs.donutChart.updateOptions({ labels: data.gender_label });
        this.$refs.barChart.updateOptions({
          xaxis: { categories: data.edu_label },
          yaxis: { max: maxValue, tickAmount: 5 },
        });

        // this.$refs.barChart.updateOptions({ yaxis: maxValue });
      });
    },
  },
  mounted() {
    // this.series = [{
    //   name: 'Education',
    //   data: [26, 407, 751, 3209, 1098, 1234, 9, 1]
    // }]
    // this.series2 = [2984, 3751]

    // WIP : Data dari DS belum rapih, perlu diolah lagi biar keluar
    // =============================================================
    // loginStore.dispatch("getListBU").then(() => {
    //   this.optionsBU = loginStore.getters.BUList;
    // });
    // console.log(this.chartOptions)
    // console.log(this.chartOptions2)
    this.dataDate = this.FormatDate("2022-08-23");
    this.fetchData();
  },
};
</script>
<style>
body {
  font-family: Arial, Helvetica, sans-serif !important;
}
.formGroup legend {
  border: none;
  top: 5px;
}
.card {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.65);
  margin-top: 4rem;
  max-width: 300px;
  max-height: 300px;
  text-align: center;
}
.card-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 3rem;
}

.card-block .rotate {
  z-index: 8;
  float: right;
  height: 100%;
}
.display-1 {
  font-size: 4.5rem;
}
.text-uppercase {
  font-size: 1.5rem;
}

.card-block .rotate i {
  color: rgba(20, 20, 20, 0.15);
  position: absolute;
  left: 0;
  left: auto;
  right: -10px;
  bottom: 0;
  display: block;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -o-transform: rotate(-44deg);
  -ms-transform: rotate(-44deg);
  transform: rotate(-44deg);
}
@media screen and (max-width: 900px) {
}
</style>
